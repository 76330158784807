import '../styles/index.css'

import $ from 'jquery';

// import 'normalize.css/normalize.css';
// import 'simplebar/dist/simplebar.css';
// import 'slick-carousel/slick/slick.css';

// import 'slick-carousel/slick/slick.js';
// import SimpleBar from 'simplebar'
// import Vote from './vote'
// import Currency from './currency'

const _dir = $('html').attr('dir');

((
    ($) => {

        $(document).on('click', '.alert--close', function () {

            var _btn = $(this);
            var _parent = _btn.closest('.alert');
            _parent.stop().slideUp(1000, function() {
                $(this).remove();
            });
        });

        // $.each($('.field-wrapper.is-required'), function (index,element) {
        //     $('element').find('label').prepend('<span class="required-sign">*</span>')
        // // <span class="required-sign">*</span>
        // })
    }
)(jQuery));
